.contact {

    // background: linear-gradient(217deg, rgba(203, 210, 218, 0.747), rgba(60, 99, 228, 0) 70.71%), 
    //             linear-gradient(127deg, rgba(25, 83, 150, 0.8), rgb(33, 157, 105) 70.71%), 
    //             linear-gradient(336deg, rgba(243, 80, 4, 0.8), rgba(25, 83, 150, 0.8) 70.71%); 
    // margin-top: -40px;

    @media screen and (max-width: 1023px) { 
    &__title {
        font-size: 2rem;
        padding-top: 5%;
    }}
    
    
    @media screen and (min-width: 1024px) {
    &__title {
        font-size: 3rem;
        padding-top: 5%;
    }}
    
    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 400px;
        font-size: 16px;
        margin-left: 36px;
        margin-right: 36px;
  
    }

    @media screen and (max-width: 767px) {
    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 400px;
        font-size: 14px;
        margin-left: 0px;
        margin-right: 0px;
    
    }}
    

    &__form-name {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    @media screen and (max-width: 767px) {
    &__form-name {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}

    @media screen and (max-width: 767px) {
    &__name-label {
        width: 33%;
    }}
    
    
    @media screen and (min-width: 768px) {
    &__name-label {
        font-size: larger;
        float:left;
        width: 35%;
        text-align:right;
        margin-right:15px;
    }}

    @media screen and (max-width: 767px) {
    &__name-field {   
        display: block;
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    font-size: large;
    background-color: lightyellow;
    /* margin-right: 20%; */
    float: left;
    width: 60%;
    }}
    
    @media screen and (min-width: 768px) {
    &__name-field {   
        display: block;
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        font-size: large;
        background-color: lightyellow;
        margin-right: 20%;
        float: left;
        width: 30%;
    }}
    
    &__form-email {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    
    @media screen and (max-width: 767px) {
    &__form-email {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;

    }}
        
    @media screen and (max-width: 767px) {
    &__email-label {
        width: 50%;
    }}
    
    @media screen and (min-width: 768px) {
    &__email-label {
        font-size: larger;
        float:left;
        width: 35%;
        text-align:right;
        margin-right:15px;
    }}

    @media screen and (max-width: 767px) {
    &__email-field {   
        display: block;
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        font-size: large;
        background-color: lightyellow;
        /* margin-right: 20%; */
        float: left;
        width: 60%;
    }}

    @media screen and (min-width: 768px) {
    &__email-field {   
        display: block;
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        font-size: large;
        background-color: lightyellow;
        margin-right: 20%;
        float: left;
        width: 30%;
    }}
   
    &__telephone {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    
    @media screen and (max-width: 767px) {
    &__telephone {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}
    
    
    @media screen and (max-width: 767px) {
    &__telephone-label {
        width: 60%;
    }}
    
    @media screen and (min-width: 768px) {
    &__telephone-label {
        font-size: larger;
        float:left;
        width: 35%;
        text-align:right;
        margin-right:15px;
    }}

    @media screen and (max-width: 767px) {
    &__telephone-field {   
        display: block;
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        font-size: large;
        background-color: lightyellow;
        margin-right: 0%;
        float: left;
        width: 60%;
    }}
    
    @media screen and (min-width: 768px) {
    &__telephone-field {   
        display: block;
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        font-size: large;
        background-color: lightyellow;
        margin-right: 20%;
        float: left;
        width: 30%;
    }}

    &__selector {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    
    @media screen and (max-width: 767px) {
    &__selector {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}
    
    
    @media screen and (max-width: 767px) {
    &__selector-label {
        width: 60%;
    }}
    
    @media screen and (min-width: 768px) {
    &__selector-label {
        font-size: larger;
        float:left;
        width: 35%;
        text-align:right;
        margin-right:15px;
    }}

    @media screen and (max-width: 767px) {
    &__selector-field {   
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        background-color: lightyellow;
        font-size: large;
        float: left;
        width: 60%;
    }}
    
    @media screen and (min-width: 768px) {
    &__selector-field {   
        display: block;
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        font-size: large;
        background-color: lightyellow;
        margin-right: 20%;
        float: left;
        width: 30%;
    }}

    &__message {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    
    @media screen and (max-width: 767px) {
    &__message {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}
    
    
    @media screen and (max-width: 767px) {
    &__message-label {
        width: 55%;
    }}
    
    @media screen and (min-width: 768px) {
    &__message-label {
        font-size: larger;
        float:left;
        width: 35%;
        text-align:right;
        margin-right:15px;
    }}

    @media screen and (max-width: 767px) {
    &__message-field {   
        display: block;
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 75px;
        font-size: large;
        background-color: lightyellow;
        float: left;
        width: 60%;
    }}

    @media screen and (min-width: 768px) {
    &__message-field {   
        display: block;
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 75px;
        font-size: large;
        background-color: lightyellow;
        margin-right: 20%;
        float: left;
        width: 30%;
    }}

    @media screen and (max-width: 599px) {
    &__button {
        margin-left: 20%;
        background-color: #379c37;
        color: midnightblue;
        font-size: 14px;
        height: 3rem;
        border-radius: 5px;
        margin-top: 0px;
        margin-right: 20%;
        box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
        border: none;
        cursor: pointer;
        outline: none;
        transition: box-shadow 399ms ease-in-out;
    }}
        
    @media screen and (min-width: 600px) {
    &__button {
        width: 250px;
        margin-left: 20%;
        background-color: #379c37;
        color: midnightblue;
        font-size: 14px;
        height: 3rem;
        border-radius: 10px;
        margin-top: 15px;
        box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
        border: none;
        cursor: pointer;
        outline: none;
        transition: box-shadow 399ms ease-in-out;

    }}
    
    @media screen and (min-width: 768px) {
    &__button {
        width: 250px;
        margin-left: 35%;
        background-color: #379c37;
        color: midnightblue;
        font-size: 24px;
        height: 3rem;
        border-radius: 10px;
        margin-top: 15px;
        box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
        border: none;
        cursor: pointer;
        outline: none;
        transition: box-shadow 399ms ease-in-out;

    }}

    @media screen and (min-width: 1024px) {
    &__button {
        width: 250px;
        margin-left: 40%;
        background-color: #379c37;
        color: midnightblue;
        font-size: 24px;
        height: 3rem;
        border-radius: 10px;
        margin-top: 15px;
        box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
        border: none;
        cursor: pointer;
        outline: none;
        transition: box-shadow 399ms ease-in-out;

    }}

    &__button:hover {
        background-color: #3da53d;
        box-shadow: inset -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
    }

    &__newsletter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 24px;
        margin-top: 2%;
    }

    @media screen and (max-width: 767px) {
    &__newsletter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 12%;
        font-size: 14px;
    }}
    
    &__intro {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 24px;
        margin-left: 12%;
        margin-right: 12%;
        margin-bottom: 2%;
    }

    @media screen and (max-width: 767px) {
    &__intro {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 14px;
        margin-left: 12%;
        margin-right: 12%;
        margin-bottom: 2%;
    }}

};