.privacy {
  
    &__container {
        margin-left: 10%;
        margin-right: 10%;
        font-size: 14px;

    }
    @media screen and (max-width: 768px) {
    &__container {
        margin-left: 10%;
        margin-right: 10%;
        font-size: 8px;
    }}  
    
}

.privacy__story-addr {
    list-style: none;
}  
.paraQ {
    font-weight: 600;
    font-style: italic;
}