.nav {

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 20%;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 3%;
        font-size: 16px;
    }

    @media screen and (max-width: 768px) {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48%;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 8%;
        font-size: 14px;
    }}

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 48%;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 3%;
        font-size: 14px;
    }}

    &__link {
        text-decoration: none;
        color: black;
        
    }

    &__invite {
        font-size: 24px;
    }
    &__apply-now-offer {
        text-decoration: none;
        position: relative;
        top: 0rem;
        padding-left: 1rem;
        font-weight: bold;
        width: 190px;
        margin-left: 7%;
        background-color: lightgray;
        color: white;
        font-size: 32px;
        height: 3rem;
        border-radius: 10px;
        margin-top: -30px;
    }
    &__apply-now-button {
        text-decoration: none;
        position: relative;
        top: 0rem;
        font-weight: 500;
        color: darkblue;
    }

    &__mopolo-link {
        text-decoration: none;
        color: black;

    }

}

.active {
    color: red;
    font-weight: 500;
}
    
