.payAmort {
    margin-left: 5%;
    margin-right: 5%; 

  &__intro {
    font-size: 2rem;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
  }  

  @media screen and (max-width: 768px) {
    &__intro {
      font-size: 14px;
      margin-left: 5%;
      margin-right: 5%;
    }}

  &__form {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    &__form {
      font-size: 14px;
    }}
  
  &__form-security-option {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__form-amount {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    &__form-amount {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}
  
  @media screen and (min-width: 769px) {
    &__amount-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__amount-field {   
    display: block;
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    font-size: large;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;
    width:20%;
  }}

  @media screen and (max-width: 768px) {
    &__amount-field {
      display: block;
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      width: 50%;
    }}

  &__form-rate {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    &__form-rate {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
  }}
  
  @media screen and (min-width: 769px) {
  &__rate-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;

  }}
  
  @media screen and (min-width: 769px) {
  &__rate-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    width: 30%;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;
    width:20%;

  }}
  @media screen and (max-width: 768px) {
  &__rate-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    width: 50%;
    background-color: lightyellow;

  }}


  &__form-amort {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

  }

  @media screen and (max-width: 768px) {
  &__form-amort {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
  }}
  
  @media screen and (min-width: 769px) {
  &__amort-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__amort-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;
    width:20%;

  }}
  
  @media screen and (max-width: 768px) {
  &__amort-field {
    display: block;
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
    width: 50%;
  }}

&__form-term {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
  &__form-term {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
  }}
  
  @media screen and (min-width: 769px) { 
  &__term-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__term-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      margin-right: 22%;
      float:left;
      width: 28%;

  }}

  @media screen and (min-width: 1000px) and (max-width: 1250px) {
    &__term-field {
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        background-color: lightyellow;
        margin-right: 15%;
        float:left;
        width: 36%;
  
    }}  

    @media screen and (max-width: 768px) {
      &__term-field {
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        background-color: lightyellow;
      }}
    

  &__form-payment {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    &__form-payment {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}
  
  @media screen and (min-width: 769px) {
  &__payment-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__payment-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;
    width:20%;
  }}

  @media screen and (max-width: 768px) {
  &__payment-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      width:50%;
  
    }}



  &__button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: 2%;
      
  }

  &__button {
      width: 250px;
      background-color: #379c37;
      color: white;
      font-size: 18px;
      height: 4rem;
      border-radius: 10px;
      margin-top: 15px;
      box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
      border: none;
      cursor: pointer;
      outline: none;
      transition: box-shadow 399ms ease-in-out;
  
      }
  
      &__button:hover {
          background-color: #3da53d;
          box-shadow: inset -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
      }
  
  }

.button {
    width: 250px;
    background-color: #379c37;
    color: white;
    font-size: 16px;
    height: 4rem;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    outline: none;
    transition: box-shadow 399ms ease-in-out;

  &:hover {
      background-color: #3da53d;
      box-shadow: inset -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
  }
}


.wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5px;
    font-size: 16px;
    

}
