.footer {

    
    @media screen and (max-width: 599px) {
    &__container { 
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
    }}
    
    @media screen and (min-width: 600px) {
    &__container { 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: white;
    }}

    &__sm-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 5%;
        margin-top: 10px;
        margin-left: 10px;
    

    }

    &__copywrite {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -10px;
        margin-bottom: 0px;
        font-size: 16px;
    }
    @media screen and (max-width: 768px) {
    &__copywrite {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -10px;
        margin-bottom: 0px;
        font-size: 14px;
    }}

    @media screen and (max-width: 767px) {
    &__copywrite {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -10px;
        margin-bottom: 0px;
        font-size: 14px;
    }}

    &__copywrite-text {
   
        margin-bottom: 0px;
    }

    &__copywrite-link {
        text-decoration: none;
        color: black;
        margin-top: 0px;
        justify-self: center;
        margin-left: 30px;
    }

    &__contact {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-decoration-style: none;
        text-decoration-color: black;
        margin-bottom: 15px;
        margin-right: 10px;
        margin-top: 5px;
        font-size: 16px;
    }

    @media screen and (min-width: 768px) {
    &__contact {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-decoration-style: none;
        text-decoration-color: black;
        margin-bottom: 15px;
        margin-right: 10px;
        margin-top: 5px;
        font-size: 14px;
    }}

    @media screen and (max-width: 767px) {
        &__contact {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration-style: none;
            text-decoration-color: black;
            margin-bottom: 15px;
            margin-right: 10px;
            margin-top: 5px;
            font-size: 14px;
        }}
     
        &__contact-phone {
        // align-self: flex-end;
        text-decoration: none;
        color: black;
    }
    &__contact-email {
        // align-self: flex-end;
        text-decoration: none;
        color: black;
    }

}