@import url('https://fonts.googleapis.com/css?family=Italiana&display=swap');

.inputCapture {
    margin-left: 5%;
    margin-right: 5%; 

  &__intro {
    font-size: 2rem;
    margin-left: 5%;
    margin-right: 5%;
  }  
  @media screen and (max-width: 768px) {
  &__intro {
    font-size: 14px;
    margin-left: 5%;
    margin-right: 5%;
  }}


  &__form {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
  &__form {
    font-size: 14px;
  }}



  &__form-name {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    &__form-name {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
  }}
  
  @media screen and (min-width: 769px) {
  &__name-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
    &__name-field {   
    display: block;
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;
    width:20%;
  }}

  @media screen and (max-width: 768px) {
    &__name-field {
      display: block;
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      width: 50%;
    }}
  

  &__form-email {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    &__form-email {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
  }}
  
  @media screen and (min-width: 769px) {
  &__email-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;

  }}

  @media screen and (min-width: 769px) {
  &__email-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    width: 30%;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;
    width:20%;

  }}

  @media screen and (max-width: 768px) {
    &__email-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      width: 50%;
      background-color: lightyellow;
  
    }}
  
  &__form-mortgage {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

  }

  @media screen and (max-width: 768px) {
  &__form-mortgage {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
  }}

  @media screen and (min-width: 769px) {
  &__mortgage-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__mortgage-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;
    width:20%;

  }}

  @media screen and (max-width: 768px) {
    &__mortgage-field {
      display: block;
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      width: 50%;
    }}

  &__form-equity {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

  }
  @media screen and (max-width: 768px) {
    &__form-equity {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}
  
  @media screen and (min-width: 769px) {
  &__equity-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__equity-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;
    width:20%;

  }}

  @media screen and (max-width: 768px) {
    &__equity-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      width:50%;
  
    }}
  
  &__form-self-income {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  &__form-self-income {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
  }}

  @media screen and (min-width: 769px) {
  &__self-inc-label {
    float:left;
    width: 67%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__self-inc-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    width: 27%;
    background-color: lightyellow;
    margin-right: 30%;
    float:left;

}}

@media screen and (max-width: 768px) {
  &__self-inc-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
    width:50%;

  }}

  &__form-self-credit {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
  &__form-self-credit {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
}}

@media screen and (min-width: 769px) {
&__self-credit-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__self-credit-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      margin-right: 22%;
      float:left;
      width: 28%;

  }}

  @media screen and (min-width: 1000px) and (max-width: 1250px) {
    &__self-credit-field {
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        background-color: lightyellow;
        margin-right: 15%;
        float:left;
        width: 36%;
  
    }}  

  @media screen and (max-width: 768px) {
  &__self-credit-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
  }}

  &__form-part-income {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    &__form-part-income {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}
  
  @media screen and (min-width: 769px) {
  &__part-inc-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__part-inc-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      margin-right: 30%;
      float:left;
      width: 20%;
  }}

  @media screen and (max-width: 768px) {
    &__part-inc-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      width:50%;
  
    }}

  &__form-part-credit {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    &__form-part-credit {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
  }}
    
  @media screen and (min-width: 769px) {
  &__part-credit-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

    
  @media screen and (min-width: 769px) {
    &__part-credit-field {
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
    margin-right: 22%;
    float:left;
    width: 28%;
  }}

  @media screen and (min-width: 1000px) and (max-width: 1250px) {
    &__part-credit-field {
        box-sizing: border-box;
        border: 1px black solid;
        border-radius: 5px;
        height: 25px;
        background-color: lightyellow;
        margin-right: 15%;
        float:left;
        width: 36%;
  
    }}  


  @media screen and (max-width: 768px) {
    &__part-credit-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
    }}
  
  
  @media screen and (min-width: 769px) {
    &__form-security {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
  }}

  @media screen and (max-width: 768px) {
    &__form-security {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
  }}


  @media screen and (min-width: 769px) {
  &__security-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__form-security-option {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;
      margin-right: 22%;
      float:left;
      width: 28%;
    
  }}

  @media screen and (max-width: 768px) {
  &__form-security-option {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-sizing: border-box;
    border: 1px black solid;
    border-radius: 5px;
    height: 25px;
    background-color: lightyellow;
  
  }}

  &__form-selfEmp {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    &__form-selfEmp {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
    }}
  
  @media screen and (min-width: 769px) {
  &__selfEmp-ind-label {
    float:left;
    width: 50%;
    text-align:right;
    margin-right:15px;
  }}

  @media screen and (min-width: 769px) {
  &__selfEmp-ind-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      width: 61%;
      background-color: lightyellow;
      margin-right: 22%;
      float:left;
      width: 28%;

  }}

  @media screen and (max-width: 768px) {
    &__selfEmp-ind-field {
      box-sizing: border-box;
      border: 1px black solid;
      border-radius: 5px;
      height: 25px;
      background-color: lightyellow;

  }}
  

  &__button {
      width: 250px;
      margin-left: 40%;
      background-color: #379c37;
      color: white;
      font-size: 24px;
      height: 3rem;
      border-radius: 10px;
      margin-top: 15px;
      box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
      border: none;
      cursor: pointer;
      outline: none;
      transition: box-shadow 399ms ease-in-out;
  
  }

  @media screen and (max-width: 650px) {
  &__button {
    width: 250px;
    margin-left: 15%;
    background-color: #379c37;
    color: white;
    font-size: 24px;
    height: 3rem;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    outline: none;
    transition: box-shadow 399ms ease-in-out;

}}

@media screen and (min-width: 651px) and (max-width: 768px) {
  &__button {
    width: 250px;
    margin-left: 30%;
    background-color: c;
    color: white;
    font-size: 24px;
    height: 3rem;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    outline: none;
    transition: box-shadow 399ms ease-in-out;

    }}

    &__button:hover {
        background-color: #3da53d;
        box-shadow: inset -2px -2px 5px lightgrey, 3px 3px 5px rgba(0, 0, 0, 0.2);
    }

}


