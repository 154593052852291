.header {

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
    }

    @media screen and (max-width: 425px) {
    &__container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        
    }}   

    &__title-container {
        width: 65%;
    }
    
    @media screen and (max-width: 425px) {
    &__title-container {
        width: 100%;
    }}

    &__title {
        font-size: 3rem;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10%;
    }
    
    
    
    @media screen and (max-width: 425px) {
    &__title {
        font-size: 2rem;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10%;
    }}   
    
    @media screen and (min-width: 426px) and (max-width: 900px) {
    &__title {
        font-size: 1.5rem;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10%;
    }}

    @media screen and (min-width: 901px) and (max-width: 1065px) {
    &__title {
        font-size: 2rem;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10%;
    }}


    &__corp {
        display: flex;
        flex-direction: column;
        width: 20%;
        height: 175px;
        align-items: center;
    }

    @media screen and (max-width: 425px) {
    &__corp {
        display: flex;
        flex-direction: column;
        width: 62%;
        height: 175px;
        align-items: center;
    }}

    @media screen and (min-width: 426px) and (max-width: 768px) {
    &__corp {
        display: flex;
        flex-direction: column;
        width: 30%;
        height: 175px;
        align-items: center;
    }}
    
    
    &__corp-logo {
        width: 275px;
        height: 200px;
        margin-bottom: 0px;
    }

    @media screen and (min-width: 426px) and (max-width: 768px) {
    &__corp-logo {
        width: 100%;
        height: 200px;
        margin-bottom: 0px;
    }}

    @media screen and (max-width: 425px) {
        &__corp-logo {
            width: 275px;
            height: 175px;
            margin-bottom: 0px;
        }}
    
    
    &__corp-info {
        font-size: 10px;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    &__corp-info2 {
        font-size: 10px;
        margin-bottom: 0px;
        margin-top: 0px;
        width: 95%;
    }

    @media screen and (min-width: 426px) and (max-width: 768px) {
    &__corp-info2 {
        font-size: 10px;
        margin-bottom: 0px;
        margin-top: 0px;
        width: 79%;
    }}

}