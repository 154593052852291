.hero {

    &__container {
        display: flex;
        flex-direction: row;
        height: 300px;
        width: 100%;
        background-color: lightgrey;
    }

    @media screen and (min-width: 320px) and (max-width: 424px) {
    &__container {
        display: flex;
        flex-direction: row;
        height: 275px;
        width: 100%;
        background-color: lightgrey;
    }}
    
    @media screen and (min-width: 425px) and (max-width: 768px) {
    &__container {
        display: flex;
        flex-direction: row;
        height: 225px;
        width: 100%;
        background-color: lightgrey;
    }}

    &__story {
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 2%;

    }

    &__story-co {
        font-weight: 600;
    }

    &__story-headline {
        font-size: 22px;
        text-align: center;
        color: darkblue;
    }
    
    @media screen and (max-width: 768px) {
    &__story-headline {
        font-size: 14px;
        text-align: center;
        color: darkblue;
    }}

    @media screen and (min-width: 769px) and (max-width: 900px) {
    &__story-headline {
        font-size: 15px;
        text-align: center;
        color: darkblue;
    }}
    
    @media screen and (min-width: 901px) and (max-width: 1175px) {
    &__story-headline {
        font-size: 18px;
        text-align: center;
        color: darkblue;
    }}
    
    &__story-link {
        font-size: 22px;
        text-decoration: none;
        align-self: center;
        color:darkblue;
        font-weight: 600;
    }

    @media screen and (max-width: 768px) {
    &__story-link {
        font-size: 12px;
        text-decoration: none;
        align-self: center;
        color:darkblue;
        font-weight: 600;
    }}
    @media screen and (min-width: 769px) and (max-width: 900px) {
    &__story-link {
        font-size: 15px;
        text-decoration: none;
        align-self: center;
        color:darkblue;
        font-weight: 600;
    }}
    
    @media screen and (min-width: 901px) and (max-width: 1175px) {
    &__story-link {
        font-size: 18px;
        text-decoration: none;
        align-self: center;
        color:darkblue;
        font-weight: 600;
    }}
    
    &__story-link:hover {
        font-size: 24px;
        text-decoration: none;
        align-self: center;
        color: darkblue;
        font-weight: 600;
    }

    @media screen and (max-width: 768px) {
    &__story-link:hover {
        font-size: 12px;
        text-decoration: none;
        align-self: center;
        color: white;
        font-weight: 600;
    }}

    @media screen and (min-width: 769px) and (max-width: 900px) {
    &__story-link:hover {
        font-size: 15px;
        text-decoration: none;
        align-self: center;
        color: white;
        font-weight: 600;
    }}

    @media screen and (min-width: 901px) and (max-width: 1175px) {
        &__story-link:hover {
        font-size: 18px;
        text-decoration: none;
        align-self: center;
        color: white;
        font-weight: 600;
    }}
    
    &__myPic {
        height: 350px;
        padding-right: 0%;
        margin-left: 0%;
        width: 350px;
        margin-top: -25px;
        border-radius: 10px;
        background-color: whitesmoke;
        border: 1px solid darkslategrey;
        margin-right: 20px;
    }
    @media screen and (max-width: 768px) {
    &__myPic {
        display: none;
    }}

}