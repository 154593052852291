.mascot {

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-left: 24px;
        margin-right: 24px;
    }

    
    &__intro {
        font-size: 24px;
    }

    &__quote {
        font-size: 32px;
        font-style: italic;
    }

    &__question {
        font-size: 32px;
        font-style: italic;
    }


    &__image {
        width: 60%;
        border-radius: 8px;
    }

    &__invite {
        font-size: 24px;
    }

    &__header-title {
        font-size: 2.9rem;
    }

    @media screen and (max-width: 768px) {
    &__header-title {
        font-size: 1.5rem;
    }}

    &__title-container {
        width: 65%;
    }
    
    @media screen and (max-width: 425px) {
    &__title-container {
        width: 100%;
    }}


}
