.story {

    &__container {
        padding-top: 2%;
        padding-bottom: 2%;
        margin-top: 3%;
        margin-left: 7%;
        margin-right: 7%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color: lightgrey;
        font-size: 22px;
    }

    @media screen and (max-width: 767px) {
    &__container {
        padding-top: 2%;
        padding-bottom: 2%;
        margin-top: 3%;
        margin-left: 7%;
        margin-right: 7%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color: lightgrey;
        font-size: 14px;
    }}

    @media screen and (min-width: 768px) and (max-width: 900px) {
    &__container {
        padding-top: 2%;
        padding-bottom: 2%;
        margin-top: 3%;
        margin-left: 7%;
        margin-right: 7%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color: lightgrey;
        font-size: 15px;
    }}
    
    @media screen and (min-width: 901px) and (max-width: 1175px) {
    &__container {
        padding-top: 2%;
        padding-bottom: 2%;
        margin-top: 3%;
        margin-left: 7%;
        margin-right: 7%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color: lightgrey;
        font-size: 18px;
    }}
    
    &__intro {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 2%;
        color: darkblue;

    }

    &__continued {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 2%;
        color: darkblue;
    }

    &__quote {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 2%;
        color: darkblue;
        background-color: white;
    }

    &__quote-js {
        font-style: italic; 
        color: darkblue;
    }

    &__close {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 2%;
        color: darkblue;
        font-weight: 600;
    }

}